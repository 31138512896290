import { Container, Row, Col } from 'react-bootstrap';
import './footer.css';

const Footer = () => {
  return (
    <footer className="site-footer bg-dark text-white mt-auto py-3">
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col xs={12} md={6} className="text-center text-md-start mb-2 mb-md-0">
            <p className="mb-0">Copyright {new Date().getFullYear()}, Vngle. All rights reserved.</p>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-end">
            <a href="https://www.vngle.com/about" className="footer-link mx-2">About</a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf80FHwD9CVDZK954uUQ_-_0nkMvBGYT1vZ6nHvXsD12H7fDg/viewform" className="footer-link mx-2">Contact</a>
            <a href="https://www.vngle.com/privacy" className="footer-link mx-2">Privacy</a>
            <a href="https://www.vngle.com/terms" className="footer-link mx-2">Terms of Use</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;