import React from 'react';
import ReactDOM from 'react-dom/client';
import Routings from './Router';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import reportWebVitals from './reportWebVitals';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { Buffer } from 'buffer';
import '@aws-amplify/ui-react/styles.css';
import '@fortawesome/fontawesome-free/css/all.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
// import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

Amplify.configure(awsExports);
disableReactDevTools();
window.Buffer = Buffer;


// const client = new ApolloClient({
//   uri: 'https://jchonuc62rdjhh3w5klm4bldce.appsync-api.us-east-1.amazonaws.com/graphql',
//   cache: new InMemoryCache()
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <ApolloProvider client={client}> */}
      <Routings />
    {/* </ApolloProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();