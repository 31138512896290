import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Admin from "./admin.js";
import ClientEntry from './pages/ClientEntry/ClientEntry';
import Reports from './App';
import ReportDetails from './pages/ReportDetails/ReportDetails';
import EWitness from "./backburner/eWitness/eWitness.js";
import UserProfile from './pages/UserProfile/UserProfile';

const Routings = () => {
  return (
    <Router>
        <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/" element={<ClientEntry />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/report/:id" element={<ReportDetails />} />
            <Route path="/eWitness" element={<EWitness/>} />
            <Route path="/user-profile" element={<UserProfile />} />
        </Routes>
    </Router>
  );
};

export default Routings;