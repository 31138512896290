import { useState } from "react";
import { Container, Row, Col  } from 'react-bootstrap';
import Navbar from "./components/navbar/DashboardNav";
import SearchContainer from "./components/search/search_container";
import DataTable from "./DataTable";
import Follow from "./components/follow_us/follow_us";
import Footer from "./components/footer/footer";
import BeatLoader from "react-spinners/BeatLoader";
import "./App.css";

function App() {
  const [sortCriteria, setSortCriteria] = useState('oldest'); // State for sorting criteria
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [reportCount, setReportCount] = useState(0);

  // Handle sorting change
  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };

  const handleReportCountChange = (count) => {
    setReportCount(count);
  };

  const resetSearch = () => {
    setSearchTerm('');
  };

  return (
    <Container fluid className="p-0" style={{backgroundColor:"#F5F5F5"}}>
      <Row className="m-0">
        {/* Navbar Column */}
        <Col xxl={2} className="p-0 w-auto">
          <Navbar resetSearch={resetSearch}/>
        </Col>
        
        {/* Main Content Column */}
        <Col className="d-flex flex-column p-0">
          {isLoading && (
            <div className="loader-container">
              <BeatLoader color="#616161" size={50} />
            </div>
          )}
          {/* Search Container */}
          <div style={{ 
            position: 'sticky', 
            top: 0, 
            zIndex: 1000,
            width: '100%',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)', /* For Safari */
            backgroundColor: 'rgba(245, 245, 245, 0.5)'
          }}>
            <SearchContainer 
              onSortChange={handleSortChange} 
              onSearchChange={setSearchTerm} 
              reportCount={reportCount} 
              style={{ width: '100%' }} 
            />
          </div>

          {/* DataTable and Follow */}
          <Row className="m-0 flex-grow-1">
            {/* DataTable Column */}
            <Col xs="auto" className="p-0">
              <DataTable 
                sortCriteria={sortCriteria} 
                searchTerm={searchTerm} 
                setIsLoading={setIsLoading} 
                onReportCountChange={handleReportCountChange}
              />
            </Col>
            
            {/* Follow Column (optional) */}
            <Col xs="auto" style={{paddingTop:"1.5rem", paddingBottom: "2rem"}}>
              <Follow />
            </Col>
          </Row>
          <Row className="m-0 d-flex w-100">
            <Footer />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default App;