import React, { useState } from 'react';
import { Row, Col, Button, Form, Image, Modal } from 'react-bootstrap';
import { useUserAttributes } from "../../hooks/useUserAttributes";
import Camera from '../../images/camera-icon.png';
import './editProfileModal.css';

const EditProfileModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userAttributes = useUserAttributes();

  // if (!userAttributes) {
  //     return <div>Loading...</div>;
  // }

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Edit Profile
      </Button>

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton style={{ padding: '2.375rem 2.375rem 0 2.375rem', borderBottom: 'none' }} className="custom-modal-header">
                <Modal.Title className="modal-title">Edit Profile</Modal.Title>
            </Modal.Header>
                <Modal.Body className="custom-modal-body" style={{ padding: '0 2.375rem' }}>
                    <p>Update your profile information.</p>
                    <div className="profile-image-container mb-3" style={{ marginBottom: '3.125rem' }}>
                        <Image
                            src={Camera}
                            className="profile-image img-fluid"
                            roundedCircle
                            style={{ width: 'auto', height: 'auto' }}
                        />
                    </div>
                    <Form style={{ marginBottom: '3.125rem' }}>
                    {userAttributes && (
                        <>
                        <Form.Group as={Row} controlId="formName" className="mb-3">
                            <Form.Label column sm={2} className="custom-label">Name</Form.Label>
                            <Col sm={10}>
                            <Form.Control type="text" defaultValue={userAttributes.name} className="custom-form-control" disabled style={{ backgroundColor: '#DEE2E6' }}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formEmail" className="mb-3">
                            <Form.Label column sm={2} className="custom-label">Email</Form.Label>
                            <Col sm={10}>
                            <Form.Control type="email" defaultValue={userAttributes.email} className="custom-form-control" disabled style={{ backgroundColor: '#DEE2E6' }} />
                            </Col>
                        </Form.Group>
                        </>
                    )}
                    <Form.Group as={Row} controlId="formBio" className="mb-3" style={{ marginBottom: '3.125rem' }}>
                        <Form.Label column sm={2} className="custom-label">Bio</Form.Label>
                        <Col sm={10}>
                        <Form.Control as="textarea" rows={3} defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." className="custom-form-control" />
                        </Col>
                    </Form.Group>
                    </Form>
                
                </Modal.Body>

                <Modal.Footer 
                    style={{ padding: '0 2.375rem 2.375rem 2.375rem', display: 'flex', borderTop: 'none' }}>
                    <Button variant="button" onClick={handleClose} className="custom-button" style={{ marginRight: '0.625' }}>
                        Cancel
                    </Button>
                    <Button variant="secondary" onClick={handleClose} className="custom-button custom-secondary-button">
                        Save Changes
                    </Button>
                </Modal.Footer>
        </Modal>
    </>
  );
};

export default EditProfileModal;