import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  width: 50px;
  height: 50px;
  background-color: #014FAC; // change as needed
  cursor: pointer;
  outline: none;
  position: relative;
  transition: 0.12s ease-in-out, background-color 0.3s ease-in-out; 

  &:hover {
    background-color: #013475;
  }
`;

const TrustIndicator = ({ toggleOverlay }) => {
  const [icon, setIcon] = useState(faFingerprint);

  const handleClick = () => {
    setIcon(icon === faFingerprint ? faTimes : faFingerprint);
    toggleOverlay();
  };

  return (
    <CircleButton onClick={handleClick}>
      <FontAwesomeIcon icon={icon} style={{width: '25px', height: '25px', color: '#EFFCFF'}} />
    </CircleButton>
  );
};

export default TrustIndicator;
