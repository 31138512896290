import './PrimaryButton.css';

const PrimaryButton = ({ variant, className, href, target, type, onClick, label, disabled, children }) => {
    const variantClass = variant === 'variant2' ? 'primary-button--variant2' : 'primary=button';

    return (
        <button 
            className={`primary-button ${variantClass} ${className}`} 
            href={href}
            target={target}
            type={type}
            onClick={onClick} 
            disabled={disabled}
        >
            {children || label}
        </button>
    );
};

export default PrimaryButton;