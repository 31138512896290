import { useState, useEffect, useCallback } from "react";
import s3 from "../../aws-config";
import axios from "axios";
import { DynamoDBClient, QueryCommand } from "@aws-sdk/client-dynamodb";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useUserAttributes } from "../../hooks/useUserAttributes";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  Image
} from "react-bootstrap";
import Navbar from "../../components/navbar/DashboardNav";
import PrimaryButton from "../../backburner/PrimaryButton/PrimaryButton";
import TrustIndicator from "../../components/TrustIndicator";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLock, faMapMarkerAlt, faUser, faIdCard, faPen, faCamera, faGlobe, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Logo from "../../images/vngle-logo.png";
import ReporterLogo from "../../images/logo_no_bg.png";
import WaterMark from "../../images/vngleWatermark.png";
import "./ReportDetails.css";
import "../../Watermark.css";

// Configure DynamoDB Client
const client = new DynamoDBClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const ReportDetails = () => {
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useAuth();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const userAttributes = useUserAttributes();
  const [isImageExpanded, setIsImageExpanded] = useState(!isOverlayVisible);

  useEffect(() => {
    setIsImageExpanded(!isOverlayVisible);
  }, [isOverlayVisible]);

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const convertDynamoDBItem = (item) => {
    return Object.entries(item).reduce((acc, [key, value]) => {
      acc[key] = value.S || value.N || value.BOOL;
      return acc;
    }, {});
  };
  
  const fetchReport = useCallback(async (id) => {
    const params = {
      TableName: "vngle-mobile-raw",
      KeyConditionExpression: "id = :id",
      ExpressionAttributeValues: {
        ":id": { S: id },
      },
    };
  
    try {
      const command = new QueryCommand(params);
      const { Items } = await client.send(command);
      if (Items && Items.length > 0) {
        const item = convertDynamoDBItem(Items[0]);
        const mediaUrl = await getMediaUrl(item.id);
        setReport({ ...item, mediaUrl });
      } else {
        console.log('No item found in DynamoDB');
        setReport(null);
      }
    } catch (err) {
      console.error("Error fetching data from DynamoDB:", err);
      setReport(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchReport(id);
    }
  }, [id, fetchReport]);

  const getMediaUrl = async (id) => {
    const params = {
      Bucket: "vngleapp-storage-2002d012152540-staging",
      Prefix: `${id}_`,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      if (data.Contents.length > 0) {
        const key = data.Contents[0].Key;
        const fileType = key.split(".").pop();
        const filename = key.split("/").pop();

        // Define Content-Disposition based on file type
        let disposition = "attachment";
        if (fileType === "mp4" || fileType === "jpeg" || fileType === "jpg") {
          disposition += `; filename="${filename}"`;
        }

        return s3.getSignedUrl("getObject", {
          Bucket: params.Bucket,
          Key: key,
          Expires: 60,
          ResponseContentDisposition: disposition,
        });
      }
    } catch (err) {
      console.error("Error retrieving file from S3:", err);
    }
    return null;
  };

  const handleDownload = async (url) => {
    if (!isAuthenticated || !userAttributes) {
      alert("User is not authenticated or user data is not loaded.");
      return;
    }

    try {
      const apiEndpoint =
        "https://1ccko210i4.execute-api.us-east-1.amazonaws.com/FirstTry/download-tracking";
      const response = await axios.post(
        apiEndpoint,
        {
          userId: userAttributes.sub,
          fileId: id,
        },
        {
          timeout: 10000,
        }
      );

      const { allowed, message } = response.data;
      if (allowed) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", true);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        alert(message);
      }
    } catch (error) {
      console.error("Failed to check download limit:", error);
      alert(
        error.response && error.response.data
          ? error.response.data
          : "An unexpected error occurred while checking the download limit. Please try again later."
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!report) {
    return <div>No data available</div>;
  }

  const cardData = [
    { title: 'Capture Time', icon: faClock, content: report.Time },
    { title: 'Vngle Verified', icon: faLock, content: 'Receipt: ' + report.receipt},
    { title: 'Capture Location', icon: faMapMarkerAlt, content: report.location },
    { title: 'Reporter Details', icon: faIdCard, content: 
    (<>
      <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
      <div className="flex items-center mb-2">
        <div className="mr-3">
          <div className="w-12 h-12 bg-gray-600 rounded-full flex items-center justify-center">
            <FontAwesomeIcon icon={faUser} className="text-2xl me-2" />
            {report.userID}
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold">{report.reporterName || 'Blake Stoner'}</h2>
          <p className="text-sm text-gray-400">{report.reporterRole || 'Contributor'}</p>
        </div>
      </div>
      <div className="flex items-center text-sm">
        <span className="font-medium"><Image src={Logo} width={20} className="me-2 ms-2"/>{"Vngle Media"}</span>
      </div>
    </div>
    </>)},
    { title: 'Edits & Versions', icon: faPen, content: 'No previous versions detected'},
    { title: 'Media Caption', icon: faCamera, content: report.title + ' in ' + report.location + ', on ' + report.Time || 'Caption information' },
    { title: 'About eWitness', icon: faInfoCircle, content: (<>
      <p className="text-sm text-gray-400">
        Vngle’s eWitness technology ensures real-time, secure verification of user-submitted content, empowering citizens to contribute authentic, trustworthy news and enhancing transparency in community-driven journalism.
      </p></>) },
    { title: 'Additional Info', icon: faGlobe, content: `Phone: ${report.phoneType} | Model: ${report.model}` },
  ];

  const isVideo = report?.mediaUrl?.split("?")[0].endsWith(".mp4");

  const renderMedia = () => {
    if (!report.mediaUrl) return null;

    if (isVideo) {
      return (
        <video
          className={`central-media ${isImageExpanded ? 'expanded' : ''}`}
          // onClick={toggleOverlay}
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
          controlsList="nodownload"
        >
          <source src={report.mediaUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <img
          src={report.mediaUrl}
          alt="Report Media"
          className={`central-media ${isImageExpanded ? 'expanded' : ''}`}
          // onClick={toggleOverlay}
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
        />
      );
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0 min-vh-100 w-auto">
        <Col xs={2} className="p-0 w-auto">
          <Navbar />
        </Col>
        <Col xs={10}>
          <Col xs={10} className="mt-2 w-auto">
            <Card className="mb-3">
              <div className="media-container">
                {isOverlayVisible && (
                  <div className="overlay">
                    <div className={`overlay-content ${isOverlayVisible ? 'visible' : ''}`}>
                      <Row className="g-3">
                        {cardData.slice(0, 4).map((card, index) => (
                          <Col key={index} xs={6} md={4}>
                            <Card className="overlay-card h-100">
                              <Card.Header>
                                <FontAwesomeIcon icon={card.icon} className="me-2" />
                                {card.title}
                              </Card.Header>
                              <Card.Body>
                                <Card.Text>{card.content}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                        <Col xs={6} md={4} className="d-flex align-items-center justify-content-center">
                          <CSSTransition
                            in={isImageExpanded}
                            timeout={300}
                            classNames="media-transition"
                          >
                            {renderMedia()}
                          </CSSTransition>
                        </Col>
                        {cardData.slice(4).map((card, index) => (
                          <Col key={index + 4} xs={6} md={4}>
                            <Card className="overlay-card h-100">
                              <Card.Header>
                                <FontAwesomeIcon icon={card.icon} className="me-2" />
                                {card.title}
                              </Card.Header>
                              <Card.Body>
                                <Card.Text>{card.content}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                )}
                <div className="watermark-container"></div>
                {report.mediaUrl &&
                  (report.mediaUrl.split("?")[0].endsWith(".mp4") ? (
                    <div className="video-wrapper">
                      <video
                        className="custom-video"
                        controls
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                        controlsList="nodownload"
                      >
                        <source src={report.mediaUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      {!isAuthenticated && <Image className="custom-watermark" src={WaterMark} alt="Watermark Logo" />}
                    </div>
                  ) : (
                    <div className="image-wrapper">
                      <img
                        src={report.mediaUrl}
                        alt="Media"
                        className="custom-img"
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                      />
                      {!isAuthenticated && <Image className="watermark" src={WaterMark} alt="Watermark Logo" />}
                    </div>
                  ))}
              </div>
              <Card.Body className="text-start">
                <Card.Title>{report.title}</Card.Title>
                <Card.Text className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                  <span className="mb-2 mb-md-0 text-wrap overflow-hidden text-truncate" style={{ maxWidth: '100%' }}>
                      <FontAwesomeIcon icon={faClock} className="me-2" />{report.Time}{" "}
                      {isAuthenticated && report.location && (<><FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 ms-2" />{report.location}</>)}
                      {isAuthenticated && report.userID && (<><Image src={ReporterLogo} width={20} className="me-2 ms-2"/>{report.userID}</>)}
                      <Image src={Logo} width={20} className="me-2 ms-2"/>{"Vngle Media"}
                  </span>
                  <span className="d-flex flex-row align-items-center">
                    {isAuthenticated && userAttributes && (
                      <PrimaryButton
                        variant="variant2"
                        className="me-2"
                        onClick={() => handleDownload(report.mediaUrl)}
                      >
                        Download
                      </PrimaryButton>
                    )}
                    {isAuthenticated && report && report.receipt && <TrustIndicator toggleOverlay={toggleOverlay} />}
                  </span>
                </Card.Text>
                <Card.Text>{report.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {/* {isAuthenticated && (
            <Col xs={2} className="mt-4 w-auto offset-md-3 offset-lg-0">
              <div className="mb-4">
                <h4>Search</h4>
                <Form>
                  <InputGroup>
                    <Form.Control className="search-field" type="search" placeholder="Search reports..." />
                    <Button variant="outline-secondary">
                      <i className="fas fa-search"></i>
                    </Button>
                  </InputGroup>
                </Form>
              </div>
            </Col>
          )} */}
        </Col>
      </Row>
    </Container>
  );
};

export default ReportDetails;