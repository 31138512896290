import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import PrimaryButton from "./backburner/PrimaryButton/PrimaryButton";

export default function Subscribe ({variant = "variant2"}) {
    const [stripe, setStripe] = useState(null);

    useEffect(()=> {
        // test key: pk_test_51JhxokEVGqTPjmUlPZ6kGscVoSTNw8j5W4Iyul3Tuzsb0KuP6juOSNfDcvrBHBqgTWv4iVtzuWoDv2nQabmDfU0r00N4NbtIA4
        loadStripe('pk_live_51JhxokEVGqTPjmUlLNmetf6EvP6sHfBItFweVI5j4TnSLB4XGAL6pUQqOwbE1QcqN2dKWsJ2aE5IuUgNkZY34UTY00QQr5uLdX').then(setStripe);
    }, []);

    const handleClick = async e => {
        if(!stripe) return;

        const { error } = await stripe.redirectToCheckout({
            lineItems:
            // test price id: price_1PzUzPEVGqTPjmUltFPrXWNE
            [{ price: 'price_1PzrniEVGqTPjmUlcLlEYqtp', quantity: 1}],
            mode: 'subscription',
            successUrl: 'https://newswire.vngle.com/reports',
            cancelUrl: 'https://newswire.vngle.com',
        });
    };
    return (
        <PrimaryButton variant={variant} onClick={handleClick}>Subscribe</PrimaryButton>
    );
};
