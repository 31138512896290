import { useState, useEffect, useCallback } from 'react';
import { Nav, Navbar, Offcanvas, Row, Col, Image, Modal } from 'react-bootstrap';
import { fetchAuthSession, signOut as amplifySignOut } from "aws-amplify/auth"; 
import { Authenticator } from '@aws-amplify/ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Subscribe from '../../Subscribe';
import PrimaryButton from '../../backburner/PrimaryButton/PrimaryButton';
import Logo from "../../images/vngle-logo.png";
import './LandingNav.css';

const LandingNav = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAuthenticator, setShowAuthenticator] = useState(false);
  const [justLoggedIn, setJustLoggedIn] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkAuthState();
  }, []);

  useEffect(() => {
    if (isAuthenticated && justLoggedIn) {
      handleCloseAuthenticator();
      navigate('/reports');
      setJustLoggedIn(false);
    }
  }, [isAuthenticated, justLoggedIn, navigate]);

  useEffect(() => {
    if (isSigningOut && !isAuthenticated) {
      navigate('/');
      setIsSigningOut(false);
    }
  }, [isSigningOut, isAuthenticated, navigate]);

  const checkAuthState = async () => {
    try {
      await fetchAuthSession();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  // Handle opening and closing of the Authenticator modal
  const handleShowAuthenticator = () => setShowAuthenticator(true);
  const handleCloseAuthenticator = () => setShowAuthenticator(false);

  const handleSignOut = useCallback(async () => {
    try {
      setIsSigningOut(true);
      await amplifySignOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Error signing out:', error);
      setIsSigningOut(false);
    }
  }, []);;

  const handleNavigation = (path) => {
    if (path !== location.pathname) {
      navigate(path);
    }
  };
  return (
    <>
      {/* Offcanvas Navbar (hidden on large screens) */}
      <Navbar expand={false} className="mb-3 d-lg-none">
        <Image src={Logo} style={{width: '100px', height: '100px'}}/>
        <Navbar.Toggle aria-controls="offcanvasNavbar" className="custom-toggler"/>
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="custom-offcanvas-landing"
          style={{ color: '#ffffff' }}
        >
          <Offcanvas.Header closeButton closeVariant="white">
            <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link as={Link} to="https://www.vngle.com/">Explore Vngle</Nav.Link>
              <Nav.Link onClick={() => handleNavigation('/')}>Home</Nav.Link>
              <Nav.Link onClick={() => handleNavigation('/reports')}>Reports</Nav.Link>
              {isAuthenticated ? (
                <Nav.Link onClick={() => handleNavigation('/user-profile')}>User Profile</Nav.Link>
              ) : ( 
                null
              )}
              <Row className="mt-auto p-0 d-flex align-self-end align-items-center">
                <Col className="d-lg-none d-block p-0 me-2 text-center">
                  <Subscribe />
                </Col>
                <Col className="d-lg-none d-block p-0 text-center">
                  {isAuthenticated ? (
                    <PrimaryButton className="m-2 ms-auto" onClick={handleSignOut}>
                      Sign Out
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton className="m-2 ms-auto" onClick={handleShowAuthenticator}>
                      Login
                    </PrimaryButton>
                  )}
                </Col>
              </Row>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>

      {/* Sidebar (shown on large screens) */}
      {/* d-none d-xxl-block */}
      <div className="landing-nav d-none d-lg-block flex-row">
        <Nav className="d-flex align-items-center flex-grow-1 mt-2">
          <Image src={Logo} style={{width: '100px', height: '100px'}}/>
          <div className='d-flex justify-content-center flex-grow-1'>
          <Nav.Link as={Link} to="https://www.vngle.com/" className="m-2">Explore Vngle</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/')} className="m-2">Home</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/reports')} className="m-2">Reports</Nav.Link>
          {isAuthenticated ? (
            <Nav.Link onClick={() => handleNavigation('/user-profile')} className="m-2">User Profile</Nav.Link>
          ) : (
            null
          )}
          </div>
          <div className="d-flex align-items-center">
            {isAuthenticated ? (
              <PrimaryButton variant="variant2" className="m-2 ms-auto" onClick={handleSignOut}>
                Sign Out
              </PrimaryButton>
            ) : (
              <PrimaryButton variant="variant2" className="m-2 ms-auto" onClick={handleShowAuthenticator}>
                Login
              </PrimaryButton>
            )}
            <Subscribe variant="default"/>
          </div>      
        </Nav>
        {/* <Col className="d-lg-block d-none p-0 text-end border border-warning">
              {isAuthenticated ? (
                    <Button onClick={handleSignOut} style={{ margin: '20px' }}>
                      Sign Out
                    </Button>
              ) : (
                <Button onClick={handleShowAuthenticator} style={{ margin: '20px' }}>
                  Sign In
                </Button>
              )}
        </Col> */}
      </div>
      {/* Authenticator Modal */}
      <Modal show={showAuthenticator} onHide={handleCloseAuthenticator} centered>
        <Modal.Body>
          <Authenticator 
            hideSignUp={true}
            components={{
              Header() {
                return (
                  <div style={{ textAlign: 'center', padding: '20px 0' }}>
                    <h1>Sign In</h1>
                  </div>
                );
              },
            }}
          >
            {({ user }) => {
              if (user) {
                setIsAuthenticated(true);
                setJustLoggedIn(true);
                return null;
              }
              return null;
            }}
          </Authenticator>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LandingNav;