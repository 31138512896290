import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormControl, DropdownButton, Dropdown, Button, Modal } from 'react-bootstrap';
import { fetchAuthSession, signOut } from "aws-amplify/auth"; 
import { Authenticator, useTheme, View } from '@aws-amplify/ui-react';
import PrimaryButton from '../../backburner/PrimaryButton/PrimaryButton';
import Help from '../help/help';
import './search_container.css';

const SearchContainer = ({ onSortChange, onSearchChange, reportCount }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortCriteria, setSortCriteria] = useState('latest');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAuthenticator, setShowAuthenticator] = useState(false);

  const handleSelect = (e) => {
    setSortCriteria(e);
    onSortChange(e);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    onSearchChange(searchTerm);
  }

  useEffect(() => {
    // Check if the user is authenticated
    fetchAuthSession()
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false));
  }, []);

  // Handle opening and closing of the Authenticator modal
  const handleShowAuthenticator = () => setShowAuthenticator(true);
  const handleCloseAuthenticator = () => {setShowAuthenticator(false); window.location.reload();};

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsAuthenticated(false);
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


  return (
    <>
    <Container fluid className="search-container p-3">
      <Row className="d-flex m-0 w-100 align-items-center">
        <Col xs='auto' className="d-flex justify-content-start">
              <h1 style={{fontFamily: "Lora, serif", fontSize: '32px', fontWeight: '700',margin: 0}}>
                Newswire
              </h1>
        </Col>
        {isAuthenticated ? (
          <>
            <Col>
              <Form className="d-flex" onSubmit={handleSearchSubmit}>
                <FormControl
                  type="search"
                  placeholder="Search reports..."
                  aria-label="Search"
                  className="search-bar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <PrimaryButton variant="variant2" className="ms-2" type='submit' >Search</PrimaryButton>
              </Form>
            </Col>
            <Col md="auto">
              <DropdownButton
                id="dropdown-basic-button"
                title={`Sort by: ${sortCriteria ? (sortCriteria === 'latest' ? 'Latest' : 'Oldest') : 'Sort by'}`}
                className="custom-dropdown"
                onSelect={handleSelect}
              >
                <Dropdown.Item eventKey="latest">Latest</Dropdown.Item>
                <Dropdown.Item eventKey="oldest">Oldest</Dropdown.Item>
              </DropdownButton>
            </Col>
          </>
          ) : (
            null
          )}
          <Col xs="auto" className="d-flex p-0 ms-auto mb-3">
              {isAuthenticated ? (<Col xs="auto" className="d-none d-lg-block p-0 mt-3"><Help /></Col>):( null )}
              <Col xs="auto" className="d-none d-lg-block p-0 mt-3 me-3">
                {isAuthenticated ? (
                    <PrimaryButton onClick={handleSignOut}>
                      Sign Out
                    </PrimaryButton>
                ) : (
                    <PrimaryButton onClick={handleShowAuthenticator}>
                      Login
                    </PrimaryButton>
                )}
              </Col>
            </Col>
            <Col xs={12} className="mt-2">
              <p className="items-count" style={{ display: isAuthenticated ? "block" : "none" }}>
                Number of Reports Found: {reportCount}
              </p>
            </Col>
      </Row>
    </Container>
    {/* Authenticator Modal */}
    <Modal show={showAuthenticator} onHide={handleCloseAuthenticator} centered>
        <Modal.Body>
          <Authenticator 
            hideSignUp={true}
            components={{
              Header() {
                return (
                  <div style={{ textAlign: 'center', padding: '20px 0' }}>
                    <h1>Sign In</h1>
                  </div>
                );
              },
            }}
          >
            {({ user }) => {
              if (user) {
                setIsAuthenticated(true);
                handleCloseAuthenticator();
              }
              return null;
            }}
          </Authenticator>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchContainer;