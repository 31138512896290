import { useState, useEffect } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';

export const useUserAttributes = () => {
    const [userAttributes, setUserAttributes] = useState(null);

    useEffect(() => {
        async function fetchAttributes() {
            try {
                const attributes = await fetchUserAttributes();
                setUserAttributes(attributes);
            } catch (error) {
                console.error('Error fetching user attributes:', error);
            }
        }

        fetchAttributes();
    }, []);

    return userAttributes;
};
