import { useState, useEffect, useCallback } from 'react';
import { Nav, Navbar, Offcanvas, Col, Modal } from 'react-bootstrap';
import { fetchAuthSession, signOut as amplifySignOut } from "aws-amplify/auth"; 
import { Authenticator } from '@aws-amplify/ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PrimaryButton from '../../backburner/PrimaryButton/PrimaryButton';
import Help from '../help/help';
import './DashboardNav.css';

const NavbarComponent = ({ resetSearch = () => {} }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAuthenticator, setShowAuthenticator] = useState(false);
  const [show, setShow] = useState(false);
  const [justLoggedIn, setJustLoggedIn] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkAuthState();
  }, []);

  useEffect(() => {
    if (isAuthenticated && justLoggedIn) {
      handleCloseAuthenticator();
      navigate('/reports');
      setJustLoggedIn(false);
    }
  }, [isAuthenticated, justLoggedIn, navigate]);

  useEffect(() => {
    if (isSigningOut && !isAuthenticated) {
      navigate('/');
      setIsSigningOut(false);
    }
  }, [isSigningOut, isAuthenticated, navigate]);

  const checkAuthState = async () => {
    try {
      await fetchAuthSession();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  // Handle opening and closing of the Authenticator modal
  const handleShowAuthenticator = () => setShowAuthenticator(true);
  const handleCloseAuthenticator = () => setShowAuthenticator(false);

  const handleSignOut = useCallback(async () => {
    try {
      setIsSigningOut(true);
      await amplifySignOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Error signing out:', error);
      setIsSigningOut(false);
    }
  }, []);;

  const handleNavigation = (path) => {
    if (path !== location.pathname) {
      navigate(path);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleReportsClick = () => {
    if (typeof resetSearch === 'function') {
      resetSearch();
    }
    handleClose();
  };

  return (
    <>
      {/* Offcanvas Navbar (hidden on large screens) */}
      <div className="icon-sidebar d-xxl-none">
        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow}>
          <i className="fas fa-angle-double-right"></i>
        </Navbar.Toggle>
        <Nav className="flex-column">
          <Nav.Link as={Link} to="https://www.vngle.com/"><i className="fas fa-globe"></i></Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/')}><i className="fas fa-home"></i></Nav.Link>
          <Nav.Link onClick={() => {
            handleReportsClick(); 
            handleNavigation('/reports');
          }}><i className="fas fa-file-alt"></i></Nav.Link>
          {isAuthenticated ? (
            <Nav.Link onClick={() => handleNavigation('/user-profile')}><i className="fas fa-user"></i></Nav.Link>
          ) : null}
        </Nav>
      </div>

      <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="start"
        show={show}
        onHide={handleClose}
        className="custom-offcanvas"   // adding style for extended sidebar
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="d-flex flex-column flex-grow-1 pe-3">
            <Nav.Link as={Link} to="https://www.vngle.com/"><i className="fas fa-globe"></i> Explore Vngle</Nav.Link>
            <Nav.Link onClick={() => handleNavigation('/')}><i className="fas fa-home"></i> Home</Nav.Link>
            <Nav.Link onClick={() => {
              handleReportsClick(); 
              handleNavigation('/reports');
            }}><i className="fas fa-file-alt"></i> Reports</Nav.Link>
            {isAuthenticated ? (
              <Nav.Link onClick={() => handleNavigation('/user-profile')}><i className="fas fa-user"></i> User Profile</Nav.Link>
            ) : null}
            <Col className="d-flex flex-column align-self-center p-0 text-center">
              {isAuthenticated ? (
                <PrimaryButton className="m-2" onClick={handleSignOut}>
                  Sign Out
                </PrimaryButton>
              ) : (
                <PrimaryButton className="m-2" onClick={handleShowAuthenticator}>
                  Login
                </PrimaryButton>
              )}
              <Help />
            </Col>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
      {/* Authenticator Modal */}
      <Modal show={showAuthenticator} onHide={handleCloseAuthenticator} centered>
        <Modal.Body>
          <Authenticator 
            hideSignUp={true}
            components={{
              Header() {
                return (
                  <div style={{ textAlign: 'center', padding: '20px 0' }}>
                    <h1>Sign In</h1>
                  </div>
                );
              },
            }}
          >
            {({ user }) => {
              if (user) {
                setIsAuthenticated(true);
                setJustLoggedIn(true);
                return null;
              }
              return null;
            }}
          </Authenticator>
        </Modal.Body>
      </Modal>

      {/* Sidebar (shown on large screens) */}
      <div className="sidebar d-none d-xxl-flex flex-column vh-100 text-white sticky-top">
        <div className="menu-title"> {/* menu title on large screen */}
          <h2>MENU</h2>
        </div>
        <Nav className="flex-column">
        <Nav.Link as={Link} to="https://www.vngle.com/" className="text-white mb-2"><i className="fas fa-globe"></i>Explore Vngle</Nav.Link>
          <Nav.Link onClick={() => handleNavigation('/')} className="text-white mb-2"><i className="fas fa-home"></i> Home</Nav.Link>
          <Nav.Link onClick={() => {
              handleReportsClick(); 
              handleNavigation('/reports');
            }} className="text-white mb-2"><i className="fas fa-file-alt"></i> Reports</Nav.Link>
          {isAuthenticated ? (
            <Nav.Link onClick={() => handleNavigation('/user-profile')} className="text-white mb-2"><i className="fas fa-user"></i> User Profile</Nav.Link>
          ) : (
            null
          )}        
        </Nav>
      </div>
    </>
  );
};

export default NavbarComponent;