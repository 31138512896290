import React, { useState, useEffect, useCallback, useRef } from "react";
import AWS from "aws-sdk";
import s3 from "./aws-config";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import { parse, parseISO } from "date-fns";
import { Image, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "./backburner/PrimaryButton/PrimaryButton";
import BeatLoader from "react-spinners/BeatLoader";
import WaterMark from "./images/vngleWatermark.png";
import "./DataTable.css"

// Configure AWS using environment variables
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.Credentials({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }),
});

const docClient = new AWS.DynamoDB.DocumentClient();

function DataTable({ sortCriteria, searchTerm, setIsLoading, onReportCountChange }) {
  const [data, setData] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useAuth();

  const itemsPerPage = 9;

  const fetchData = useCallback((startKey = null, fetchAll = false) => {
    setIsLoading(true);

    const params = {
      TableName: "vngle-mobile-raw",
      IndexName: "sorted-index",
      KeyConditionExpression: "GSI = :gsiValue",
      ExpressionAttributeValues: {
        ":gsiValue": "global"
      },
      ScanIndexForward: true,
      ExclusiveStartKey: startKey,
    };
  
    if (!searchTerm.trim() && !fetchAll) {
      params.Limit = itemsPerPage;
    }
  
    docClient.query(params, (err, result) => {
      if (err) {
        console.error("Error fetching data from DynamoDB:", err);
        setIsLoading(false);
        return;
      }
  
      const fetchedData = result.Items.map(item => ({
        ...item,
        thumbnailUrl: null,
      }));
  
      setData(prevData => {
        const newData = startKey ? [...prevData, ...fetchedData] : fetchedData;
        const uniqueData = Array.from(new Set(newData.map(item => item.id)))
          .map(id => newData.find(item => item.id === id));
        return uniqueData;
      });
      setLastEvaluatedKey(result.LastEvaluatedKey);
      setIsLoading(false);
      setLoadingMore(false);
    });
  }, [searchTerm, setIsLoading, itemsPerPage]);

  useEffect(() => {
    setData([]);
    setLastEvaluatedKey(null);
    fetchData(null, searchTerm.trim() !== "");
  }, [searchTerm, fetchData]);

  useEffect(() => {
    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        const dateA = parseDate(a.createAt).getTime();
        const dateB = parseDate(b.createAt).getTime();
        return sortCriteria === 'latest' ? dateB - dateA : dateA - dateB;
      });
      return sortedData;
    });
  }, [sortCriteria]);

  const handleLoadMore = () => {
    if (lastEvaluatedKey && isAuthenticated) {
      setLoadingMore(true);
      fetchData(lastEvaluatedKey);
    }
  };

  const getThumbnailUrl = async (id) => {
    const params = {
      Bucket: "vngleapp-storage-2002d012152540-staging",
      Prefix: `${id}_`,
    };
    try {
      const data = await s3.listObjectsV2(params).promise();
      if (data.Contents.length > 0) {
        // Check if a thumbnail image exists
        const thumbnailKey = data.Contents.find(item => item.Key.toLowerCase().includes('thumbnail'));
        const key = thumbnailKey ? thumbnailKey.Key : data.Contents[0].Key;
        return s3.getSignedUrl("getObject", {
          Bucket: params.Bucket,
          Key: key,
          Expires: 60,
        });
      }
    } catch (err) {
      console.error("Error retrieving file from S3:", err);
    }
    return null;
  };

  const parseDate = (dateString) => {
    if (!dateString) return new Date(0);

    try {
      if (dateString.includes("T")) return parseISO(dateString);
      if (dateString.includes(":") && dateString.length === 19) {
        const replacedString = dateString.replace(/:/g, "-").replace(" ", "T");
        return parseISO(replacedString);
      }
      return parse(dateString, "MMMM dd, yyyy h:mm a", new Date());
    } catch (error) {
      console.error(`Error parsing date string "${dateString}":`, error);
      return new Date(0);
    }
  };

  const filteredData = searchTerm.trim() !== ""
    ? data.filter((item) => item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase()))
    : data;

  const sortedData = filteredData.sort((a, b) => {
    const dateA = parseDate(a.createAt).getTime();
    const dateB = parseDate(b.createAt).getTime();
    return sortCriteria === 'latest' ? dateB - dateA : dateA - dateB;
  });

  const getColumnClass = (totalItems) => {
    if (totalItems === 1) return "col-12";
    if (totalItems === 2) return "col-sm-12 col-md-6";
    return "col-sm-12 col-md-6 col-lg-4";
  };

  const MediaItem = React.memo(({ item, isAuthenticated, getThumbnailUrl }) => {
    const [mediaUrl, setMediaUrl] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const videoRef = useRef(null);

    const handleContextMenu = useCallback((e) => {
      e.preventDefault();
      return false;
    }, []);
  
    useEffect(() => {
      let isMounted = true;
      const loadMedia = async () => {
        setIsLoading(true);
        try {
          // Fetch thumbnail URL
          const url = await getThumbnailUrl(item.id);
          if (isMounted) {
            setThumbnailUrl(url);
            setMediaUrl(url);
            // Simulate a minimum loading time of 500ms for smoother transitions
            await new Promise(resolve => setTimeout(resolve, 500));
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error loading media:", error);
          if (isMounted) setIsLoading(false);
        }
      };
      loadMedia();
      return () => { isMounted = false; };
    }, [item.id, getThumbnailUrl]);
  
    const handleVideoLoad = () => {
      setIsVideoReady(true);
    };
  
    const isVideo = mediaUrl && mediaUrl.split("?")[0].endsWith(".mp4");
  
    return (
      <div style={{ position: 'relative', width: '100%', height: '350px', overflow: 'hidden' }} onContextMenu={handleContextMenu}>
        <div 
          className={`wireframe-container ${isLoading ? 'loading' : 'loaded'}`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'opacity 0.3s ease-in-out',
            opacity: isLoading ? 1 : 0,
          }}
        >
          <div 
            style={{
              width: '50%',
              height: '50%',
              backgroundColor: '#e0e0e0',
              borderRadius: '8px',
            }}
          />
        </div>
        
        {!isLoading && thumbnailUrl && (
          <>
            <img
              src={thumbnailUrl}
              alt="Loading..."
              style={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                width: "100%", 
                height: "100%", 
                objectFit: 'cover',
                opacity: isVideo && isVideoReady ? 0 : 1,
                transition: 'opacity 0.3s ease-in-out',
                cursor: 'pointer'
              }}
              onContextMenu={handleContextMenu}
            />
            
            {isVideo && (
              <video
                ref={videoRef}
                src={mediaUrl}
                style={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover',
                  opacity: isVideoReady ? 1 : 0,
                  transition: 'opacity 0.3s ease-in-out',
                  cursor: 'pointer'
                }}
                preload="auto"
                muted
                playsInline
                disablePictureInPicture
                className="custom-image-effect"
                onLoadedData={handleVideoLoad}
                onContextMenu={handleContextMenu}
              />
            )}
            
            {isVideo && (
              <div 
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  borderRadius: '50%',
                  padding: '15px',
                  zIndex: 101,
                }}
              >
                <FontAwesomeIcon icon={faPlay} style={{color: 'white'}} />
              </div>
            )}
            
            {!isAuthenticated && (
              <Image 
                className="watermark" 
                src={WaterMark} 
                alt="Watermark Logo" 
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  opacity: 0.4,
                  pointerEvents: 'none',
                  zIndex: 100
                }} 
              />
            )}
          </>
        )}
      </div>
    );
  }); 

  useEffect(() => {
    // Update the report count whenever filteredData changes
    onReportCountChange(filteredData.length);
  }, [filteredData, onReportCountChange]);

  return (
    <div className="container mt-4">
      <div className="row">
        {sortedData.map((item, index) => (
          <div
            key={item.id}
            className={`${getColumnClass(sortedData.length)} mb-4`}
            style={{
              display: isAuthenticated || (!isAuthenticated && index < 6) ? "block" : "none",
            }}
          >
            <div
              className="card h-100"
              onClick={
                isAuthenticated || (!isAuthenticated && index < 6)
                  ? () => navigate(`/report/${item.id}`)
                  : null
              }
            >
              <div className="card-body data">
                <MediaItem item={item} isAuthenticated={isAuthenticated} getThumbnailUrl={getThumbnailUrl}/>
                <h5 className="mt-2 custom-card-title hover-underline">{item.title || "No title"}</h5>
                <p className="card-text">
                  {item.Time || "No Date"}
                </p>
                <Button className="custom-more-button" onClick={() => navigate(`/report/${item.id}`)}>More</Button> 
              </div>
            </div>
          </div>
        ))}
      </div>
      {isAuthenticated && lastEvaluatedKey && (
        <div className="text-center mt-4">
          <PrimaryButton
            className="mb-5"
            onClick={handleLoadMore} 
            disabled={loadingMore}
          >
            {loadingMore ? <BeatLoader color="#ffffff" size={8} /> : 'Load More'}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

export default DataTable;