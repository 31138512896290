import { useState } from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import LandingNav from '../../components/navbar/LandingNav';
import Subscribe from '../../Subscribe';
import PrimaryButton from '../../backburner/PrimaryButton/PrimaryButton';
import Footer from '../../components/footer/footer';
import { Drawer } from "antd";
import { HiMiniVideoCamera } from 'react-icons/hi2';
import { CiSquarePlus } from "react-icons/ci";
import { MdVerified } from "react-icons/md";
import Mockup from "../../images/mac_mockup.png"
import './ClientEntry.css'; 

const ClientEntryPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  return (
    <Container fluid className='client-entry-body'>
      <Drawer 
        className="ant-drawer ant-drawer-body"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        size="large"
      >
        <iframe
          title="Vngle Prospect Requests"
          src="https://docs.google.com/forms/d/e/1FAIpQLSe6ldOiPulBcAuvanlk-uGJzpsAOf9_ftMOaoJL36rmU5iSew/viewform?embedded=true"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          style={{ width: "100%", height: "calc(100% - 3px)" }}
        >
          Loading…
        </iframe>
      </Drawer>
      <Row className='test-bg'>
        <Row className='w-100 m-0 p-0'>
          <LandingNav />
        </Row>
        <Row className="custom-bg text-white p-5 custom-height w-100 m-0">
          <Col xl={7} className="text-start d-flex flex-column justify-content-center">
            <h1 className="custom-h1">Reliable News. Exclusive Access.</h1>
            <p className="custom-p">
              Register and subscribe to our newswire to access a wide range of exclusive news content, videos, and provenance credentials.
            </p>
            <div>
                <button className="learn-more" onClick={handleOpenDrawer}>
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                  </span>
                  <span className="button-text">Request Demo & Price Quote</span>
                </button>
            </div>
          </Col>
          <Col xl={5} className="d-flex justify-content-center align-items-center">
            <Image src={Mockup} className='me-1 mt-5' style={{ width: '100%', height: 'auto' }} />
          </Col>
        </Row>

        <Row className="p-5 custom-2ndrow-bg w-100 m-0">
          <Col lg={6} className="align-self-center text-start">
              <p className="custom-first-p">Key Benefits</p>
            <h2 className="custom-h2">Unlock Exclusive Content and Features</h2>
            <p className="custom-second-p">
              Register and subscribe to our CMS to access a wide range of exclusive news content, videos, and personalized recommendations.
            </p>
          </Col>
          <Col lg={6} className="d-flex align-items-center">
            <Row>
              <Col md={6} xs={12} className="p-3 text-start">
                <HiMiniVideoCamera size={24} color='white'/>
                <h3 className="custom-h3">Download Raw Videos</h3>
                <p className="custom-third-p">Access raw video footage directly from our reporters.</p>
                <br></br>
                <MdVerified size={24} color='white'/>
                <h3 className='custom-h3'>Verified Content</h3>
                <p className="custom-third-p">Access a wide range of exclusive news articles and videos not available to the general public.</p>
              </Col>
              <Col md={6} xs={12} className="p-3 text-start">
              <CiSquarePlus size={24} color='white'/>
              <h3 className="custom-h3">Exclusive Content</h3>
                <p className="custom-third-p">Access news content that has been thoroughly fact-checked.</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="p-5 custom-bg-third w-100 m-0">
          <Row className="pt-5 text-start">
            <Col>
              <h2 className="custom-3rd-h2">Subscription Plan</h2>
              <p className="custom-3rd-p">Start accessing our exclusive content today.</p>
            </Col>
          </Row>

          <Row className="justify-content-center">
          <Col md={6} xs={12} className="p-3">
              <Card className="h-100" style={{backgroundColor: '#FECB3E'}}>
                <Card.Body className="custom-text-left">
                  <Card.Title className="mb-3 custom-3rd-card">Premium</Card.Title>
                  <Card.Text>
                    <ul className="list-unstyled">
                      <li><span style={{color: 'green'}}>✓</span> Access to all reports</li>
                      <li><span style={{color: 'green'}}>✓</span> All video content available</li>
                      <li><span style={{color: 'blue'}}>&#9719;</span> Bookmark reports</li>
                      <li><span style={{color: 'green'}}>✓</span> Download raw or edited media</li>
                      <li><span style={{color: 'blue'}}>&#9719;</span> Download available metadata</li>
                    </ul>
                  </Card.Text>
                  <div className="d-flex mt-auto">
                    <Subscribe />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} xs={12} className="p-3">
              <Card className="h-100" style={{backgroundColor: '#FECB3E'}}>
                <Card.Body className="d-flex flex-column custom-text-left">
                  <Card.Title className="mb-3 custom-3rd-card">Interested in a Trial Opportunity?</Card.Title>
                  <Card.Text className="flex-grow-1">
                    <ul className="list-unstyled">
                      <li>To explore our newswire service, click the button below and complete the form with your company details. 
                        We’ll provide more information about our trial opportunity tailored to your business needs.</li>
                    </ul>
                  </Card.Text>
                  {/* <a href="https://docs.google.com/forms/d/1fydgV8_MqE48TW5jeD7LGjguznHwbnCMhpuqs0fBGog/edit?ts=66e35418" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: '#f8f8f8'}}> */}
                    <PrimaryButton variant="variant2" className="mt-auto" onClick={handleOpenDrawer}>
                        Request Trial Information
                    </PrimaryButton>
                  {/* </a> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Row>
        <Footer />
      </Row>
    </Container>
  );
};

export default ClientEntryPage;