import { Container, Row, Col, Image } from "react-bootstrap";
import { useUserAttributes } from "../../hooks/useUserAttributes";
import Navbar from "../../components/navbar/DashboardNav";
import Camera from "../../images/camera-icon.png";
import EditProfileModal from "../../components/editProfileModal/editProfileModal";
import "./UserProfile.css";

const UserProfile = () => {
  const userAttributes = useUserAttributes();

  // if (!userAttributes) {
  //     return <div>Loading...</div>;
  // }

  return (
    <Container fluid className="p-0" style={{backgroundColor: "#F5F5F5"}}>
      <Row className="m-0 d-flex flex-row vh-100">
        <Col xxl={2} className="p-0 w-auto">
          <Navbar />
        </Col>
        <Col className="d-flex flex-column justify-content-center align-items-center w-auto">
          <Image
            src={Camera}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
            }}
          />
          {userAttributes && (
            <>
              <h2 className="profile-name">{userAttributes.name}</h2>
              <p className="profile-email">{userAttributes.email}</p>
              <p className="profile-info"></p>
            </>
          )}
          <EditProfileModal />
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
