import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './eWitness.css';

const eWitness = () => {
  return (
    <Container fluid className="info-bg">
      <Row>
      <Col>
          <h1>eWitness Technology at Vngle</h1>
          <p className="text-justify">
            At Vngle, we believe in the power of community-driven news to bring authenticity and transparency to journalism. Our eWitness technology plays a pivotal role in achieving this goal by empowering everyday citizens to become active participants in the news-gathering process. Here’s how we utilize eWitness technology to revolutionize community journalism.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>What is eWitness Technology?</h2>
          <p className="text-justify">
            eWitness technology enables real-time verification and authentication of multimedia content submitted by users. By leveraging advanced algorithms and secure data channels, we ensure that the photos, videos, and audio recordings shared by our community are genuine, unaltered, and trustworthy.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="custom-bullet text-justify">
          <div>
            <h2>Key Features of eWitness Technology</h2>
            <ul>
              <li>
                <strong>Real-Time Verification:</strong> eWitness technology provides instant verification of submitted content. This ensures that the information we share with our audience is accurate and reliable.
              </li>
              <li>
                <strong>Geolocation and Timestamping:</strong> Every piece of content is tagged with precise geolocation data and a timestamp. This adds a layer of authenticity, confirming that the event occurred at a specific place and time.
              </li>
              <li>
                <strong>Tamper-Proof Security:</strong> Our technology employs robust encryption methods to prevent any tampering or unauthorized alterations to the submitted content. This guarantees that what you see is exactly what was captured.
              </li>
              <li>
                <strong>User Authentication:</strong> To maintain the integrity of our platform, users undergo a verification process before they can submit content. This helps us build a community of trusted contributors.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="custom-bullet text-justify">
          <div>
            <h2>How eWitness Technology Benefits Our Audience</h2>
            <ul>
              <li>
                <strong>Enhanced Trust:</strong> By ensuring the authenticity of user-submitted content, we build trust with our audience. They can be confident that the news they consume is factual and unmanipulated.
              </li>
              <li>
                <strong>Local Insights:</strong> eWitness technology allows us to gather news from the ground level, providing unique local insights that mainstream media often overlook.
              </li>
              <li>
                <strong>Rapid Reporting:</strong> Real-time verification means that we can share news stories quickly, keeping our audience informed with up-to-date information.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="custom-bullet">
          <div>
            <h2>Real Stories from Real People</h2>
            <p className="text-justify">
              Our eWitness technology has enabled us to cover a wide range of stories, from local events and community issues to breaking news. Here are a few examples of how eWitness has made a difference:
            </p>
            <ul>
              <li>
                <strong>Community Protests:</strong> Verified footage from local protests has given a voice to community concerns, providing a platform for underrepresented groups.
              </li>
              <li>
                <strong>Natural Disasters:</strong> Real-time videos and photos from disaster zones have helped us deliver timely and accurate updates, aiding in relief efforts.
              </li>
              <li>
                <strong>Cultural Events:</strong> eWitness submissions have highlighted cultural festivals and events, showcasing the diversity and vibrancy of our communities.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Join the eWitness Community</h2>
          <p className="text-justify">
            We invite you to be a part of our eWitness community. By contributing your firsthand accounts and multimedia content, you help us uphold our commitment to integrity, transparency, and authenticity in journalism. Together, we can create a news platform that truly reflects the voices and experiences of our communities.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default eWitness;
