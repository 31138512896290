import { useState } from 'react';
import { Container, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import "./help.css"
// import FeedbackForm from '../../backburner/feedbackform/FeedbackForm';

const Help = () => {
    const [hoverIndex, setHoverIndex] = useState(null);

    const handleMouseEnter = index => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    const dropdownItems = [
        { text: 'FAQ', href: 'https://www.vngle.com/faq/' },
        { text: 'Privacy', href: 'https://www.vngle.com/privacy' },
        { text: 'Terms of Use', href: 'https://www.vngle.com/terms' },
        { text: 'Submit feedback', href: 'https://docs.google.com/forms/d/e/1FAIpQLScpXPlS3MOqr9-P5E4A_F2JFt4dfspI-OY2W-xlAPWUOT9yoA/viewform' },
        { text: 'Vngle Product Registration form', href: 'https://docs.google.com/forms/d/1eBDOZFa9VphbjC4itSGYloQLjspmyflBu79HUPvJIdY/edit'}, 
        // { text: 'CMS Client Demo Feedback form', href: 'https://docs.google.com/forms/d/19C2j-4mO95qsiu9hl4-hXytgIy6bBHqUu5Q7NeL70VQ/edit'}
    ];

    return (
        <Container>
            <Dropdown>
                <Dropdown.Toggle style={{ background: 'none', border: 'none' }}>
                    <FontAwesomeIcon className="help-icon" icon={faQuestionCircle} size="2x" title="Help" aria-label="Help" />
                </Dropdown.Toggle>

                <Dropdown.Menu style={{backgroundColor: '#333333'}}>
                    {dropdownItems.map((item, index) => (
                        <Dropdown.Item
                            key={index}
                            className="text-white"
                            href={item.href}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            style={{backgroundColor: hoverIndex === index ? '#555555' : '#333333'}}
                        >
                            {item.text}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Container>
    );
}

export default Help;
