import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './follow_us.css'; // Import your CSS file for styling
const FollowUsComponent = () => {
    return (
        <div className="follow-us-component">
            <div className="content">
                <div className="follow-us">
                    <h2>Follow Us</h2>
                    <div className="social-icons">
                        <FontAwesomeIcon
                            icon={faFacebook}
                            size="2x"
                            title="Facebook"
                            onClick={() => window.open('https://www.facebook.com/vnglestories/', '_blank')}
                            cursor="pointer"
                        />
                        <FontAwesomeIcon
                            icon={faTwitter}
                            size="2x"
                            title="Twitter"
                            onClick={() => window.open('https://twitter.com/vnglestories', '_blank')}
                            cursor="pointer"
                        />
                        <FontAwesomeIcon
                            icon={faInstagram}
                            size="2x"
                            title="Instagram"
                            onClick={() => window.open('https://www.instagram.com/vnglestories/', '_blank')}
                            cursor="pointer"
                        />
                        <FontAwesomeIcon
                            icon={faLinkedin}
                            size="2x"
                            title="LinkedIn"
                            onClick={() => window.open('https://www.linkedin.com/company/vngle/', '_blank')}
                            cursor="pointer"
                        />
                    </div>
                </div>
                <div className="paragraph">
                    <p>Stay up-to-date with the latest news and insights from our nonpartisan agency. Follow us on social media for the latest updates.</p>
                </div>
            </div>
        </div>
    );
};
export default FollowUsComponent;